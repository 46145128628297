import { useRef } from "react";

const AccordionItem = ({ data, active, onToggle }) => {
    const { title, body } = data;
    const contentEl=useRef();
    return (
        <div className={`accordion-item ${active ? "active":""}`}>
            <h2 className="mb-0">
            <button className="button primary fit small" type="button" onClick={onToggle}>{title}</button>
            </h2>
            <li style={{ "listStyle": "none" }}>
                <div ref={contentEl} className="body_wrapper" style={active ? {height:contentEl.current.scrollHeight}:{height:"0px"}}>
                    <div className="body_answer">{body}</div>
                </div>
            </li>
        </div>
    );
};

export default AccordionItem;