import { Link } from "react-router-dom";

function FooterPage() {
    return (
        <footer id="footer">
            <p className="copyright" style={{textIndent:"0px"}}>&copy; 2020. All rights reserved. Creada por: <Link to={{ pathname: "https://www.linkedin.com/in/jorge-ignacio-vaira-ortiz-585225a2/" }} target="_blank">Jorge Vaira</Link>. Diseño: <Link to={{ pathname: "https://html5up.net" }} target="_blank"> HTML5 UP</Link>.</p>
        </footer>
    );
}

export default FooterPage;