import { Link } from 'react-router-dom';
import FooterPage from './Footer';

function SidebarPage() {
    return (
        <div className='inner'>
            <nav id="menu">
                <header className='major'>
                    <h2>Menú</h2>
                </header>
                <ul>
                    <li><Link to='/'><i className="fa fa-home" style={{"fontSize":"1.5em"}}></i> <strong>Inicio</strong></Link></li>
                    <li><Link to='quienesSomos'><i className="fa fa-user" style={{"fontSize":"1.5em"}}></i> <strong>Quienes Somos</strong></Link></li>
                    <li><Link to='/historia'><i className="fa fa-industry" style={{"fontSize":"1.5em"}}></i> <strong>Historia</strong></Link></li>
                    <li><Link to='/galeria'><i className="fa fa-images" style={{"fontSize":"1.5em"}}></i> <strong>Galería de Fotos</strong></Link></li>
                    <li><Link to='/catalogo'><i className="fa fa-boxes" style={{"fontSize":"1.5em"}}></i> <strong>Artículos</strong></Link></li>
                    <li><Link to='/contacto'><i className="fa fa-map-marker" style={{"fontSize":"1.5em"}}></i> <strong>Ubicación y Contacto</strong></Link></li>
                </ul>
            </nav>
            <section>
                <ul className="contact">
                <li className="icon solid fa-map-marker-alt lista justified"><strong>IRIARTE N° 281 (Parque Industrial)</strong> 6360. General Pico, La Pampa</li>
                <li className='icon solid fa-phone lista'>Teléfono 1: <a href='tel:+542302435788'>(02302) 435788</a></li>
                    <li className='icon solid fa-phone lista'>Teléfono 2: <a href='tel:+542302436888'>(02302) 436888</a></li>
                    <li className="icon solid brands fa-whatsapp lista"><a href='https://wa.me/+5492302417408' target="_blank" rel="noopener noreferrer">+5492302417408</a></li>
                    <li className="icon fa-clock lista"><strong style={{"fontSize":"1.2em"}}>Horario de Atención:</strong><br />
                    <i className='fa fa-arrow-right custom-color-icon'></i> Lunes a Viernes de 8 a 17 hs. <br />
                    <i className='fa fa-arrow-right custom-color-icon'></i> Sábados de 8 a 12 hs.
                    </li>
                    <li className='icon solid fa-envelope lista'><a href='mailto:garroap@gmail.com'>garroap@gmail.com</a></li>
                </ul>
            </section>
            <FooterPage />
        </div>
    );
}

export default SidebarPage;
