import pic01 from '../images/pic01.jpg';
import pic02 from '../images/pic02.jpg';
import pic03 from '../images/pic03.jpg';
import pic04 from '../images/pic04.jpg';
import pic05 from '../images/pic10.jpg';

export const SliderData = [
    {
        image: pic01,
        title: 'Imagen1'
    },
    {
        image: pic02,
        title: 'Imagen2'
    },
    {
        image: pic03,
        title: 'Imagen3'
    },
    {
        image: pic04,
        title: 'Imagen4'
    },
    {
        image: pic05,
        title: 'Imagen5'
    }
]