import Accordion from '../components/Accordion';
import demoLogo from '../images/pic11.jpg';

function ArticulosPage() {
    return (
        <div>
            <section>
                <header className="main centered">
                    <h1>Listado de Artículos</h1>
                </header>
            </section>
            <section>
                <Accordion />
            </section>
            <section>
                <span className="image main"><img src={demoLogo} alt="" /></span>
            </section>
        </div>
    );
}

export default ArticulosPage;
