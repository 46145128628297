import { Link } from "react-router-dom";
import demoLogo from '../images/pic11.jpg';
import companyLogo from '../images/Texto Aguadas Pampeanas.png';

function HeaderPage() {
    return (
        <div>
            <header id="header">
                <Link to='/' className='logo'><strong>Aguadas Pampeanas</strong></Link>
            </header>
            <section id="banner">
                <div className='content'>
                    <header className='container'>
                        <img src={demoLogo} alt='' className='headerImg' />
                        <h1 style={{ "textAlign": "center" }} className='overlayText'>Aguadas Pampeanas</h1>
                    </header>
                </div>
            </section>
        </div>
    );
}

export default HeaderPage;
