import demoLogo from '../images/pic11.jpg';

function QuienesSomosPage() {
    return (
        <div>
            <header className="main centered">
                <h1>Quienes Somos</h1>
            </header>
            <p className='justified sangria'>
                Somos una Industria Metalúrgica con Fundición propia de Hierro y Bronce, ubicada en el Parque Industrial de la ciudad de General Pico, La Pampa, con una trayectoria de más de 30 años atendiendo las necesidades del Mercado Nacional.
            </p>
            <p className='justified sangria'>
                Nos dedicamos a la fabricación y comercialización de todos los productos necesarios para la extracción de agua y su óptimo funcionamiento, con marcada orientación hacia la Actividad Agropecuaria, entre nuestros principales productos se destacan: una amplia variedad de bombas, cilindros y repuestos afines.
            </p>
            <br />
            <span className="image main"><img src={demoLogo} alt="" /></span>
        </div>
    );
}

export default QuienesSomosPage;
