
function HomePage() {
    return (
        <div>
            <section>
                <header className="major2" style={{"textAlign":"center"}}>
                    <h2 className="accordionStyle">Qué nos Caracteriza</h2>
                </header>
                <div className="row">
                    <div className="col-6">
                        <ul style={{ "listStyle": "none", "color": "black" }}>
                            <li className="bigLetters"><i className="fa fa-check-circle custom-color-icon"></i> <strong>COMPROMISO</strong></li>
                            <li className="bigLetters"><i className="fa fa-check-circle custom-color-icon"></i> <strong>RESPONSABILIDAD</strong></li>
                        </ul>
                    </div>
                    <div className="col-6">
                        <ul style={{ "listStyle": "none", "color": "black" }}>
                            <li className="bigLetters"><i className="fa fa-check-circle custom-color-icon"></i> <strong>EXPERIENCIA</strong></li>
                            <li className="bigLetters"><i className="fa fa-check-circle custom-color-icon"></i> <strong>CALIDAD</strong></li>
                        </ul>
                    </div>
                </div>
            </section>
            <section>
                <header className="major2" style={{"textAlign":"center"}}>
                    <h2 className="accordionStyle">Nuestros Objetivos</h2 >
                </header>
                <div className="features">
                    <article style={{ "width": "auto" }}>
                        <p className="sangria justified">Trabajamos incasablemente para ser reconocidos y posicionarnos como una Empresa Líder en el Mercado Nacional, basándonos en tres conceptos fundamentales:</p>
                    </article>
                </div>
                <div className="features">
                    <article>
                        <span className="icon fa-check-circle"></span>
                        <div className="content">
                            <p className="camelCase bigLetters">Brindar la mejor atención y asesoramiento</p>
                        </div>
                    </article>
                    <article>
                        <span className="icon fa-check-circle"></span>
                        <div className="content">
                            <p className="camelCase bigLetters">Dar rápida y efectiva respuesta a las necesidades de todos nuestros clientes a lo largo del País</p>
                        </div>
                    </article>
                </div>
                <div className="features">
                    <article>
                        <span className="icon fa-check-circle"></span>
                        <div className="content">
                            <p className="camelCase bigLetters">Ofrecer productos de calidad</p>
                        </div>
                    </article>
                </div>
            </section>
        </div>
    );
}

export default HomePage;
