export const AccordionData = [
    {
        title: 'Cilindros',
        body: <div className="row">
            <div className="col-6">
                <ul style={{"listStyle":"none", "fontWeight":"600"}}>
                    <li><i className="fa fa-check-circle custom-color-icon"></i> Cilindros a Tiretas</li>
                    <ul style={{ "listStyle": "none" }}>
                        <li><i className="fa fa-arrow-alt-circle-right"></i> Con Válvula de Bronce Suelta</li>
                        <li><i className="fa fa-arrow-alt-circle-right"></i> Con Válvula Incorporada</li>
                    </ul>
                    <li><i className="fa fa-check-circle custom-color-icon"></i> Cilindros Roscados Comunes</li>
                    <ul style={{ "listStyle": "none" }}>
                        <li><i className="fa fa-arrow-alt-circle-right"></i> Hierro</li>
                        <li><i className="fa fa-arrow-alt-circle-right"></i> Bronce</li>
                    </ul>
                    <li><i className="fa fa-check-circle custom-color-icon"></i> Cilindros Roscados Reforzados</li>
                    <ul style={{ "listStyle": "none" }}>
                        <li><i className="fa fa-arrow-alt-circle-right"></i> Todo Bronce c/ Pistón Pera 2 Cueros/3 Cueros</li>
                    </ul>
                </ul>
            </div>
            <div className="col-6">
                <ul style={{"listStyle":"none", "fontWeight":"600"}}>
                    <li><i className="fa fa-check-circle custom-color-icon"></i> Cilindros Prensa Estopa</li>
                    <ul style={{ "listStyle": "none" }}>
                        <li><i className="fa fa-arrow-alt-circle-right"></i> Hierro</li>
                        <li><i className="fa fa-arrow-alt-circle-right"></i> Bronce</li>
                    </ul>
                    <li><i className="fa fa-check-circle custom-color-icon"></i> Cilindros a Bridas</li>
                    <ul style={{ "listStyle": "none" }}>
                        <li><i className="fa fa-arrow-alt-circle-right"></i> De Hierro con Pistón Común</li>
                        <li><i className="fa fa-arrow-alt-circle-right"></i> De Bronce con Pistón Todo Bronce</li>
                        <li><i className="fa fa-arrow-alt-circle-right"></i> De Bronce con Pistón Pera 2 Cueros/3 Cueros</li>
                    </ul>
                </ul>
            </div>
        </div>
    },
    {
        title: 'Bombas',
        body: <div>
            <ul style={{"listStyle":"none", "fontWeight":"600"}}>
                <li>
                    <i className="fa fa-check-circle custom-color-icon"></i> A Diafragma
                </li>
                <li>
                    <i className="fa fa-check-circle custom-color-icon"></i> Aljibe
                </li>
                <li>
                    <i className="fa fa-check-circle custom-color-icon"></i> Elevar Cuerpo de Hierro/Bronce Livianas/Reforzadas
                </li>
            </ul>
        </div>
    },
    {
        title: 'Repuestos',
        body: <div>
            <div className="row">
                <div className="col-6">
                    <ul style={{ listStyle: "none", fontWeight:"600" }}>
                        <li><i className="fa fa-check-circle custom-color-icon"></i> Cilindros</li>
                        <ul style={{ listStyle: "none" }}>
                            <li><i className="fa fa-arrow-alt-circle-right"></i> Tapas/Bases (Hierro/Bronce)</li>
                            <li><i className="fa fa-arrow-alt-circle-right"></i> Jaulas/Arañas</li>
                            <li><i className="fa fa-arrow-alt-circle-right"></i> Saltarines</li>
                            <li><i className="fa fa-arrow-alt-circle-right"></i> Pistones Comunes (Hierro/Bronce/Pera)</li>
                            <li><i className="fa fa-arrow-alt-circle-right"></i> Válvulas de Bronce</li>
                            <li><i className="fa fa-arrow-alt-circle-right"></i> Cuerpos de Bronce Lisos (2mm.)/Roscados (2mm./3mm.)</li>
                        </ul>
                    </ul>
                </div>
                <div className="col-6">
                    <ul style={{ listStyle: "none", fontWeight:"600" }}>
                        <li><i className="fa fa-check-circle custom-color-icon"></i> Bombas</li>
                        <ul style={{ listStyle: "none" }}>
                            <li><i className="fa fa-arrow-alt-circle-right"></i> A Diafragma</li>
                            <li><i className="fa fa-arrow-alt-circle-right"></i> Aljibe</li>
                        </ul>
                        <li><i className="fa fa-check-circle custom-color-icon"></i> Guarniciones de Cuero</li>
                        <ul style={{ listStyle: "none" }}>
                            <li><i className="fa fa-arrow-alt-circle-right"></i> Para Cilindros</li>
                            <li><i className="fa fa-arrow-alt-circle-right"></i> Para Bombas</li>
                        </ul>
                    </ul>
                </div>
            </div>

        </div>
    }
]