import { useState } from "react";
import { SliderData } from "./SliderData";

const ImageSlider = ({ slides }) => {
    const [current, setCurrent] = useState(0);
    const length = slides.length;

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    };
    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    };

    if (!Array.isArray(slides) || slides.length <= 0) {
        return null;
    }

    return (
        <section className='slider'>
            <button className="button primary sliderLeftButton" type="button" onClick={prevSlide}><i className='fa fa-arrow-left'></i></button>
            <button className="button primary sliderRightButton" type="button" onClick={nextSlide}><i className='fa fa-arrow-right'></i></button>
            {SliderData.map((imagen, index) => {
                return (
                    <div className={index===current ? 'slide active': 'slide'} key={index}>
                        {index===current && (<img src={imagen.image} alt={imagen.title} className='image' />)}
                    </div>
                );
            })}
        </section>
    );
};

export default ImageSlider;