import ImageSlider from "../components/ImageSlider";
import { SliderData } from "../components/SliderData";

function GaleriaPage() {
    return (
        <div>
            <h1 className="centered">Galería de Fotos</h1>
            <p className="sangria justified">Conozcanos un poco más</p>
            <ImageSlider slides={SliderData}/>
        </div>
    );
}

export default GaleriaPage;
