import { useState } from "react";
import { AccordionData } from "./AccordionData";
import AccordionItem from "./AccordionItem";

const Accordion=()=>{
    const [clicked, setClicked]=useState("0");
    const handleToggle=(index)=>{
        if(clicked===index){
            return setClicked("0");
        }
        setClicked(index);
    }
    return (
        <ul className='accordion custom-list'>
            {AccordionData.map((data, index)=>(
                <AccordionItem onToggle={()=>handleToggle(index)} active={clicked===index} key={index} data={data} />
            ))}
        </ul>
    );
}

export default Accordion;