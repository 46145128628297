import { useRef } from "react";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

function UbicacionPage({childToParent}) {
    const nombreRef = useRef();
    const telefonoRef = useRef();
    const emailRef=useRef();
    const asuntoRef=useRef();
    const messageRef = useRef();
    //SweetAlert
    const mySwal = withReactContent(Swal);

    function submitHandler(evt){
        evt.preventDefault();
        const inputNombre = nombreRef.current.value;
        const inputTelefono = telefonoRef.current.value;
        const inputEmail=emailRef.current.value;
        const inputAsunto=asuntoRef.current.value;
        const inputMessage = messageRef.current.value;
        //Show loading screen
        const showScreen = true;
        childToParent(showScreen);
        nombreRef.current.value = "";
        telefonoRef.current.value = "";
        emailRef.current.value="";
        asuntoRef.current.value="";
        messageRef.current.value = "";
        //Send formData to BackEnd
        const formEmailData = new FormData();
        formEmailData.append("NombreApellido", inputNombre);
        formEmailData.append("Telefono", inputTelefono);
        formEmailData.append("Mail", inputEmail);
        formEmailData.append("Asunto", inputAsunto);
        formEmailData.append("Message", inputMessage);
        const requestOptions = {
            method: "POST",
            body: formEmailData
        };
        fetch("api/Email", requestOptions)
            .then(function (res) {
                if (res.ok) {
                    childToParent(!showScreen);
                    mySwal.fire({
                        title: '<h3>¡Exito!</h3>',
                        text: 'El correo se ha enviado correctamente. Aguadas se contactará al correo proporcionado',
                        icon: 'success',
                        showCloseButton: true,
                        focusConfirm: false,
                        confirmButtonText: '<p class="sweetalertStyle"><i class="fa fa-thumbs-up"></i> Genial!</p>'
                    });
                } else {
                    childToParent(!showScreen);
                    mySwal.fire({
                        title: '<h3>¡Error!</h3>',
                        text: 'No fue posible enviar el correo debido a un problema con el servidor',
                        icon: 'error',
                        showCancelButton: true,
                        cancelButtonText: '<p class="sweetalertStyle">Ok</p>'
                    });
                }
            })
            .catch(function (error) {
                childToParent(!showScreen);
                mySwal.fire({
                    title: '<h3>¡Error!</h3>',
                    text: 'Hubo un problema con la petición de envío',
                    icon: 'error',
                    showCancelButton: true,
                    cancelButtonText: '<p class="sweetalertStyle">Ok</p>'
                });
            })
    }

    return (
        <div>
            <div>
                <header className="main centered">
                    <h1>Donde nos ubicamos</h1>
                </header>
                <p className="justified sangria">
                    Nos podes encontrar en calle <strong>IRIARTE N° 281</strong>, en el Parque Industrial de la ciudad de General Pico, provincia de La Pampa, de lunes a viernes de 8 a 17 hs, y sábados de 8 a 12 hs.
                </p>
                <br />
                <iframe title="Ubicación Aguadas Pampeanas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.9758707584815!2d-63.77520788501497!3d-35.67759612261097!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95c3633c784b4be3%3A0x569c5fd69741a367!2sAguadas%20Pampeanas!5e0!3m2!1ses-419!2sar!4v1605705559606!5m2!1ses-419!2sar"
                    width="100%"
                    height="400px"
                    frameBorder="0"
                    style={{ "border": 0 }}
                    allowFullScreen=""
                    aria-hidden="false"
                    tabIndex="0"
                />
                <header className="main centered">
                    <h1>Escribinos</h1>
                </header>
                <p className="sangria justified">
                    Podés enviarnos un correo completando el siguiente formulario que se encuentra a continuación:
                </p>
                <form id="contactForm" method="POST" action="" encType="multipart/form-data" onSubmit={submitHandler}>
                    <div className="row gtr-uniform">
                        <div className="col-12">
                            <label htmlFor="nombre">* Nombre y Apellido</label>
                            <input type="text" id="nombre" placeholder="Juan Perez" required ref={nombreRef} />
                        </div>
                        <div className="col-12">
                            <label htmlFor="telefono">* Número de Teléfono</label>
                            <input type="text" id="telefono" placeholder="2302123456" required ref={telefonoRef} />
                        </div>
                        <div className="col-12">
                            <label>Dirreción de Correo Electrónico (e-mail, Opcional)</label>
                            <input type="email" name="email" id="email" placeholder="Email" ref={emailRef} />
                        </div>
                        <div className="col-12">
                            <label>* Asunto</label>
                            <input type="text" name="asunto" id="asunto" placeholder="Asunto" ref={asuntoRef} required />
                        </div>
                        <div className="col-12">
                            <label>* Mensaje</label>
                            <textarea name="message" id="message" placeholder="Escribí tu mensaje" ref={messageRef} rows="6" required ></textarea>
                        </div>
                        <div className="col-12">
                            <ul className="actions">
                                <li>
                                    <input type="submit" value="Enviar Mensaje" className="primary" />
                                </li>
                                <li><input type="reset" value="Limpiar Formulario" /></li>
                            </ul>
                        </div>
                        <div className="col-12">
                            <div className="alert alert-danger" role="alert">
                                <p className="sangria justified"><strong>NOTA: </strong> Los campos marcados con * son obligatorios. Si no cuenta con correo electrónico no hay problema, basta con que nos provea un número de teléfono para contactarlo ante cualquier consulta.</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default UbicacionPage;
