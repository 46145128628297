import { Route, Switch } from 'react-router';
import HomePage from './pages/Home';
import ArticulosPage from './pages/Articulos';
import QuienesSomosPage from './pages/QuienesSomos';
import HistoriaPage from './pages/Historia';
import GaleriaPage from './pages/Galeria';
import ContactoPage from './pages/Ubicacion';
import HeaderPage from './shared/Header';
import SidebarPage from './shared/Sidebar';
import { useState } from 'react';

function App() {
    const [showScreen, setShowScreen] = useState(false);
    const childToParent = (childData) => {
        setShowScreen(childData);
    }
  return (<div>
    <div id="wrapper">
      <div id="main">
        <div className='inner'>
          <HeaderPage />
          <Switch>
            <Route path='/' exact>
              <HomePage />
            </Route>
            <Route path='/quienesSomos'>
              <QuienesSomosPage />
            </Route>
            <Route path='/historia'>
              <HistoriaPage />
            </Route>
            <Route path='/galeria'>
              <GaleriaPage />
            </Route>
            <Route path='/catalogo'>
              <ArticulosPage />
            </Route>
                      <Route path='/contacto'>
                          <ContactoPage childToParent={childToParent} />
            </Route>
          </Switch>
        </div>
      </div>
      <div id="sidebar">
        <SidebarPage />
      </div>
      </div>
      {
          showScreen ?
          <div id="loadScreen">
              <div className="loader" ></div>
          </div>
          : null
      }
  </div>
  );
}

export default App;