import demoLogo from '../images/pic11.jpg';

function HistoriaPage() {
    return (
        <div>
            <header className="main centered">
                <h1>Nuestra Historia</h1>
            </header>
            <p className='sangria justified'>
                AGUADAS PAMPEANAS es una empresa fundada a fines de la década del ’80, por una pareja de jóvenes, que con mucho esfuerzo y luego de un estudio pormenorizado, deciden abrirse camino e insertarse en el mercado ya que, sin lugar a dudas, había un faltante de oferta a una demanda ávida de productos de calidad y necesitada de una atención más ágil, cordial y directa.
            </p>
            <p className='sangria justified'>
                Con esas bases de pensamiento, se coloca la piedra fundamental de una empresa, que  gracias a la trayectoria y experiencia, lograría posicionarse como una de las empresas líderes en el Mercado Nacional del Cilindro. Hoy, Aguadas Pampeanas, tiene presencia desde General Pico – La Pampa a casi todas las Provincias Ganaderas Argentinas.
            </p>
            <br />
            <span className="image main"><img src={demoLogo} alt="" /></span>
        </div>
    );
}

export default HistoriaPage;
